.list_user {
    //margin-top: 30px;
    margin-bottom: 30px;
    .label-search {
        margin-top: 20px;
    }

    .ant-form-item-label > label {
        margin-right: 20px;
    }

    input[type='number'],
    input[type='password'],
    input[type='text'],
    textarea {
        height: 32px;
    }
    .stop-btn {
        border-radius: 4px;
        color: $red-5;
        border-color: $red-5;
        background-color: $pink-1;
    }
}

.card-title {
    font-size: 24px;
    padding-bottom: 10px;
    padding-top: 10px;
}

.custom-table {
    .detail-button {
        background-color: $blue-1;
        border-radius: 4px;
        color: #4587c0;
        border-color: #4587c0;
    }
}

.ant-table-container {
    box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
}

.ant-table-thead {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
}

.ant-card-body {
    padding: 0px 24px 0px 24px;
}

.ant-card-bordered {
    border-radius: 10px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}

h1.ant-typography {
    margin-bottom: 0.5em;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 600;
    font-size: 38px;
}

.custom-table-category {
    .detail-button {
        border-color: #4587c0;
        border-radius: 4px;
        color: #4587c0;
    }
    .ant-table-thead .ant-table-cell {
        font-weight: bold;
        font-family: 'KozGoPr6-Bold', serif;
    }
}

.my-place {
    .ant-row {
        .ant-col:not(:first-child) {
            border-top: 1px solid #f0f0f0;
        }
    }
}

.mg-16 {
    margin: 16px 0;
}

.ant-table-thead > tr > th {
    background: #a2c9d3;
    text-align: center !important;
    color: white;
}

.ant-table table {
    text-align: center !important;
    border: #282c34;
}

.ant-menu-title-content {
    margin-left: 8px;
}

.ant-breadcrumb-link {
    line-height: 30px;
    margin-left: 10px;
}

.border_table {
    border-bottom: 1px solid #f0f0f0;
}

.ant-form-item {
    margin-bottom: 20px;
}

//Create-notification
@media screen and (min-width: 768px) {
    .date-setting {
        width: 70%;
    }
}
@media screen and (max-width: 767px) {
    .date-setting {
        width: 100%;
    }
}

//category
.color-category {
    height: 25px;
}
.text-validate-cate {
    margin: 5px 0 0 125px;
}
