@import '../variables';

.list-detail-charger {
    .mg-16 {
        margin: 16px 0;
    }
    .ml-40 {
        margin-left: 40px;
    }

    .mgb-10 {
        margin-bottom: 10px !important;
    }

    .ant-table table {
        text-align: center !important;
    }

    .ant-form-item {
        margin-bottom: 0 !important;
    }

    .ant-input-group > .ant-input:first-child,
    .ant-input-group-addon:first-child {
        border-radius: $border-radius;
    }

    .ant-input-search > .ant-input-group > .ant-input-group-addon:last-child {
        padding: 0 0 0 10px !important;
        left: 0 !important;
    }

    .ant-input-group-addon {
        background: none;
    }

    .input-search {
        border-radius: $border-radius;
        width: 100%;
    }

    .ant-form-item-label > label {
        margin-right: 16px;
    }

    input[type='number'],
    input[type='password'],
    input[type='text'],
    textarea {
        height: 32px;
    }
    .unset-bold {
        label {
            font-weight: unset !important;
            font-family: 'KozGoPr6', serif !important;
        }
    }
    .stop-btn {
        border-radius: 4px;
        color: $red-5;
        border-color: $red-5;
        background-color: $pink-1;
    }
}

.detail-charger {
    .ant-tabs-top > .ant-tabs-nav,
    .ant-tabs-bottom > .ant-tabs-nav,
    .ant-tabs-top > div > .ant-tabs-nav,
    .ant-tabs-bottom > div > .ant-tabs-nav {
        margin: 0 !important;
    }
    .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab,
    .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
        background-color: #ffffff;
        border-radius: 6px 6px 0 0;
        margin-left: 0 !important;
    }
    .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active,
    .ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab-active {
        border-bottom: 2px solid #34c2c2;
    }
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
        color: #34c2c2;
    }
    .ant-tabs-tab {
        font-weight: 500;
    }
    .no-bold-label {
        label {
            font-family: 'KozGoPr6', serif;
        }
        .ant-form-vertical .ant-form-item-label,
        .ant-col-24.ant-form-item-label,
        .ant-col-xl-24.ant-form-item-label {
            padding: 0 !important;
        }
    }
    .ant-table-thead > tr > th {
        background: #fafafa !important;
    }
    .button-post-nitto {
        width: 200px;
        border-radius: 50px;
    }
    .ant-form-item-label>label.ant-form-item-required::before{
        content: url("../../images/place/ic-required.svg");
        transform: scale(1.8);
    }
    .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
        margin-right: 14px;
        font-size: 11px;
    }
    .content-no-required-1 {
        .ant-form-item-label label{
            margin-left: 24px!important;
        }
    }
    .ant-btn-primary {
        border-radius: 50px;
    }
    .resCd-background {
        background-color: $background-white;
        color: rgba(0, 0, 0, 0.85);
    }
}
