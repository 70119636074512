@import '../variables';

.list-detail-charger {
    min-height: 100vh;

    .type-charger {
        font-size: 9px;
    }

    .label-search {
        margin-top: 20px;
    }

    .detail-button {
        border-radius: 4px;
    }

    .btn-search {
        background-color: $btn-primary;
        border-radius: 4px;
        color: $background-white;
        padding: 0 22px 0 22px;
    }

    .btn-cancel {
        border-radius: 4px;
        color: $button-black-color;
    }

    .btn-info {
        background-color: $text-black;
    }

    .ant-table table {
        text-align: center !important;
        border: #282c34;
    }

    .ant-menu-title-content {
        margin-left: 8px;
    }

    .ant-breadcrumb-link {
        line-height: 30px;
        margin-left: 10px;
    }

    .border_table {
        border-bottom: 1px solid $button-secondary-color;
    }

    .my-charger {
        .ant-row {
            .ant-col:not(:first-child) {
                border-top: 2px solid $button-secondary-color;
            }
        }
    }

    .slave {
        background-color: $brown-2;
        border-radius: 4px;
        color: $gray-1;
        margin: 0 !important;
        font-size: 14px;
    }

    .master_charger {
        background-color: $blue-4;
        border-radius: 4px;
        color: $gray-1;
        margin: 0 !important;
        font-size: 14px;
    }

    .mg-16 {
        margin: 16px 0;
    }

    .charging-history {
        border-radius: 4px;
        color: #686868;
        border-color: #707070;
    }
    .charging-history:hover {
        border-radius: 4px;
        color: $text-white;
        background-color: #707070;
    }

}

.place-name {
    .title {
        font-weight: bold;
        font-family: 'KozGoPr6-Bold', serif;
        margin-right: 8px;
    }
}


