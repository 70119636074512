@import '../variables';

.index-place {
    .ant-table table {
        text-align: center !important;
    }

    .ant-form-inline .ant-form-item {
        margin-right: 6px;
    }

    .ant-form-item-label > label {
        margin-right: 20px;
    }

    .ant-form-item {
        margin-bottom: 0 !important;
    }

    a,
    area,
    button,
    [role='button'],
    input:not([type='range']),
    label,
    select,
    summary,
    textarea {
        margin-bottom: 10px;
    }

    input[type='number'],
    input[type='password'],
    input[type='text'],
    textarea {
        height: 32px;
    }
    .total-number-charger {
        font-size: 60px;
        text-align: center;
        height: 100%;
    }
    .stop-btn {
        border-radius: 4px;
        color: $red-5;
        border-color: $red-5;
        background-color: $pink-1;
    }
}

.text-type-place {
    font-size: 12px;
}
.mg-16 {
    margin: 16px 0 !important;
}
.mgr-8 {
    margin-right: 8px;
}

.booked {
    border-left: 3px solid $green-5;
}
.mgl-15 {
    margin-left: 15px;
}
.mgl-25 {
    margin-left: 25px;
}
.mgl-30 {
    margin-left: 30px;
}
.mgb-25 {
    margin-bottom: 25px;
}
.mgb-30 {
    margin-bottom: 30px;
}
.mgt-5 {
    margin-top: 5px;
}
.mgb-5 {
    margin-bottom: 5px;
}
.available {
    border-left: 3px solid $light-blue-4;
}
.suspension {
    border-left: 3px solid $yellow-4;
}
.unavailable {
    border-left: 3px solid $red-4;
}

//charger-status
.text-type-status {
    font-size: 10px;
}
.mgb-50 {
    margin-bottom: 47px;
}
.mgb-55 {
    margin-bottom: 55px;
}
.line-height-status {
    line-height: 35px;
}
.detail-button-place {
    border-radius: 4px;
    color: $btn-primary;
    border-color: $btn-primary;
    background-color: $blue-1;
}
.detail-button-place:hover {
    border-radius: 4px;
    color: $btn-primary;
    border-color: $btn-primary;
    background-color: #c8d2f6;
}
.detail-charging-equipment {
    border-radius: 4px;
    color: $btn-primary-new;
    border-color: $btn-primary-new;
}
.detail-charging-equipment:hover {
    border-radius: 4px;
    color: $text-white;
    background-color: $btn-primary-new;
}

@media screen and (min-width: 576px) {
    .width-50 {
        margin-left: -40px;
    }
}
@media screen and (min-width: 1200px) {
    .width-right-20 {
        margin-right: 30px;
    }
}
@media screen and (min-width: 1600px) {
    .width-left-20 {
        margin-left: 30px;
    }
    .charger-type-50 {
        margin-left: -10px;
    }
}
