.create-account {
    .ant-form-item-label {
        text-align: left;

        label {
            font-weight: 600;
        }
    }
    .place-multi-dropdown {
        display: flex;
        flex-direction: row;
    }
    .create-charger-input {
        .ant-input-group-addon {
            background-color: white !important;
            border: none !important;
        }
    }

    .label-url {
        margin-left: 10px;
    }
    .name-bold {
        font-family: 'KozGoPr6-Bold', serif;
    }
    .ant-checkbox-group {
        width: 100%;
    }
    .button-row {
        margin-left: 10px;
    }
    .ant-radio-wrapper {
        font-family: 'KozGoPr6', serif;
        font-weight: unset;
    }
    .ant-checkbox-wrapper {
        font-family: 'KozGoPr6', serif;
        font-weight: unset;
    }
    .submit-btn {
        margin-bottom: 0 !important;
        margin-top: 0 !important;
    }
}
